import React from 'react'
import SEO from './seo'
import Header from './header'
import Navbar from './navbar'
import Footer from './footer'

function Layout({ children, pageContext: { page } }) {
  return (
    <React.Fragment>
      <SEO {...page} />
      {/* <Navbar/> */}
      <Header/>
      <div className="flex flex-col min-h-screen">
        <div className="flex-grow max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-5xl w-full">
          <main className="flex-grow mb-8">{children}</main>
        </div>
      </div>
      <Footer/>
    </React.Fragment>
  )
}

export default Layout
