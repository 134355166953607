import React from 'react'

const Header = () => {
  return (
         <div class = ''>
            <section class="bg-header-100 text-gray-100">
            <div class="container mx-auto flex flex-col items-center px-4 py-10 text-center md:py-8 md:px-10 lg:px-32 xl:max-w-3xl">
                <p class="px-8 text-base">Expansive library of wisdom</p>
                    <h1 class="text-2xl font-bold tracking-tight text-white sm:text-3xl md:text-4xl">Articles and Blogs
                    </h1>
                    <p class="px-8 mt-2 text-base">Find articles on all things.</p>
                
                    <br></br>
                </div>
            </section>
            
        </div>

  )
}

export default Header